import { template as template_0aa24380ca7b4178872c726c0cbf967e } from "@ember/template-compiler";
const SidebarSectionMessage = template_0aa24380ca7b4178872c726c0cbf967e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
